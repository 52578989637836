#error-empty{
  min-height: 77vh;
  background-color: #fcf4e1;
  padding: 25px 15px;

  .inside{
    display: inline-block;
    width: 100%;
    text-align: center;

    .img-logo{
      width: 290px;
      max-width: 65%;
    }

    h1{
      color: $brown2;
      font-size: 2.0rem;
      font-weight: 600;
    }
  }
}
