#videos-page{
  position: relative;

  .main-section{
    padding-top: 15px;
    padding-bottom: 35px;

    .box-videos{
      margin-top: 0;

      .col-video{
        padding-top: 14px;
        padding-bottom: 14px;

        iframe{
          width: 100%;
          height: 370px;
        }
      }
    }

    @media screen and (max-width: 1199px){
      .box-videos{
        .col-video{
          iframe{
            height: 330px;
          }
        }
      }
    }
    @media screen and (max-width: 991px){
      .box-videos{
        .col-video{
          iframe{
            height: 250px;
          }
        }
      }
    }
  }
}
