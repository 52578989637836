#home-page{

	// == Banners ==
	.banner-section{
		padding: 0 0 15px 0;

		.swiper-container{
			box-shadow: 0 3px 5px rgba(0,0,0,0.4);

			.swiper-wrapper{
				.swiper-slide{
					img{
						width: 100%;
						// display: block;
					}
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}
		}

		.swiper-desktop{}
		.swiper-mobile{
			display: none;
		}

		@media screen and (max-width: 991px){
			.swiper-desktop{
				display: none;
			}
			.swiper-mobile	{
				display: block;
			}
		}
	}
	// == ==

	// == Products ==
	.products-section{
		.col-products{
			.carousel-t1{
				.swiper-button-prev, .swiper-button-next{
					top: 32%;
				}
			}
		}
	}
	// == ==

	// == Categories ==
	.categories-section{
		margin-top: 35px;
		margin-bottom: 15px;
		padding-top: 30px;
		padding-bottom: 30px;
		color: #ffffff;
		background-color: $brown;

		.col-title{
			margin-bottom: 10px;
			text-align: center;

			h2{
				font-size: 1.8rem;
				font-weight: 600;
			}
		}

		.col-info{
			margin-top: 15px;
			margin-bottom: 15px;
			text-align: center;

			.box-info{
				position: relative;
				display: inline-block;
				margin: 0 auto;
				width: 430px;
				max-width: 100%;
				text-decoration: none !important;
				background-color: rgba(255,255,255,0.1);
				@include transition(250ms);
				@extend .placed-backg;

				&:hover{
					opacity: 0.80;
				}

				&:before{
					content: " ";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0,0,0,0.4);
				}

				.img{
					width: 100%;
				}

				.inside{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 5;
					@include flex-center-xy();

					h5{
						position: relative;
						display: inline-block;
						padding-left: 15px;
						padding-right: 15px;
						width: 100%;
						color: #ffffff;
						font-size: 1.3rem;
						font-weight: 600;
						text-align: center;
						text-transform: uppercase;
						text-shadow: 2px 2px 2px rgba(0,0,0,0.6);
						line-height: 1.28;
						z-index: 4;
					}
				}
			}
		}

		@media screen and (max-width: 767px){
			margin-top: 25px;

			.col-info{
				margin-top: 10px;
				margin-bottom: 10px;

				.box-info{
					width: 100%;
				}
			}
		}
	}
	// == ==

	// == Info section ==
	.info-section{
		padding-top: 40px;
		padding-bottom: 35px;
		margin-top: 30px;
		color: #ffffff;
		background-color: $red;

		.col-info{
			margin-top: 10px;
			margin-bottom: 10px;
			text-align: center;

			.title{
				margin-bottom: 22px;
				font-size: 1.70rem;
				font-weight: 600;
			}

			.box-icon{
				display: block;

				img{
					margin-bottom: 32px;
				}
			}

			ul{
				padding-left: 19px;
				text-align: left;
				font-size: 1.05rem;

				li{
					margin-top: 2px;
					margin-bottom: 6px;
				}
			}
		}
	}
	// == ==

	// == Brands section ==
	.brands-section{
		.col-brands{
			margin-bottom: 15px;

			.swiper{
				.swiper-slide{
					.box-brand{
						@extend .flex-center-xy;
						flex-wrap: wrap;
						flex: 0 0 100%;
						max-width: 100%;
						height: 155px;

						.inside{
							display: inline-block;
							width: 100%;
							text-align: center;

							img{
								max-width: 85%;
								max-height: 95px;
							}
						}
					}
				}
			}
		}
	}
	// == ==

	// == Instagram ==
	.instagram-section{
		position: relative;
	}
	// == ==

}
