// == Titles ==
.col-s-title{
  padding-top: 20px;
  padding-bottom: 14px;

  .title{
    color: $brown;
    font-size: 2.05rem;
    font-weight: 700;
    text-align: center;
    // text-transform: uppercase;
    text-transform: capitalize;

    &.no-capita{
      text-transform: inherit !important;
    }
  }
}
// == ==
