#page-info-t1{
  position: relative;
  color: #2b2a2a;
  background-color: #ffffff;
  overflow: hidden;

  .bg-image{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
  }

  .content-section{
    padding-top: 35px;
    padding-bottom: 35px;
    text-align: justify;

    >.row{
      min-height: 58vh;
    }

    .col-info{
      color: #464646;
      font-size: 0.95rem;
      line-height: 1.4;

      .page-title{
        color: $brown;
        font-size: 1.95rem;
        font-weight: 700;
        text-align: center;
      }

      .subtitle-sm{
        color: $brown;
        font-size: 1.05rem;
        font-weight: 600;
      }

      ul{
        padding-left: 15px;
        list-style-type: circle;
      }

      form{
        position: relative;
        display: inline-block;
        margin-top: 25px;
        width: 100%;
        max-width: 100%;
      }

      .form-group{
        .form-control, .custom-select{
          padding-left: 1.1rem;
          padding-right: 1.1rem;
          border: 1px solid $brown;
          border-radius: 8px;
        }

        .custom-select{
          option{
            &:first-child{
              color: #999999;
            }
          }
        }
      }

      .btn-send{
        padding: 0.60rem 0.75rem;
        width: 260px;
        max-width: 100%;
        background-color: $brown;
        border-color: $brown;
        font-size: 1.05rem;
        font-weight: 500;

        &:hover{
          background-color: $brown2;
          border-color: $brown2;
        }
      }

      a{
        &.brown{
          color: #b37733;

          &:hover, &:active, &:focus{
            color: #9b6425;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px){
    .bg-image{
      width: 100%;
      opacity: 0.17;
    }
  }
  @media screen and (max-width: 575px){
    .bg-image{
      width: 111%;
      background-position: top center;
    }

    .content-section{
      .col-info{
        .page-title{
          font-size: 1.80rem;
        }
      }
    }
  }

}

.faqs-page{
  .content-section{
    .col-table{
      flex: 0 0 380px;
      max-width: 380px;

      .box-table{
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        height: 100%;
        color: #ffffff !important;
        text-decoration: none !important;
        text-align: center;
        background-color: $red;

        .box-top{
          display: inline-block;
          margin-top: 20px;
          padding: 28px 15px 5px 15px;
          width: 100%;

          .icon{
            font-size: 5.0rem;
          }
        }

        .box-bottom{
          display: inline-block;
          margin-bottom: 20px;
          width: 100%;
          padding: 20px;
        }

        &:hover{
          opacity: 0.85;
          background-color: $red2;
          @include cp-property(transform, translateY(-6px));
        }
      }
    }

    .tabla-page{
      #faqs-box{
        order: 1;
      }
    }
  }
}

.dealers-page{
  .content-section{
    >.row{
      min-height: inherit !important;
    }
  }

  .info-color-section{
		padding-top: 18px;
		padding-bottom: 18px;
		color: #ffffff;
		background-color: $red;

    .col-s-title{
      margin-bottom: 10px;

      .title{
        color: #ffffff;
      }
    }

		.col-info{
			margin-top: 14px;
			margin-bottom: 14px;
      font-size: 1.05rem;
			text-align: center;

      .title{
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.4;
        text-align: center;
      }
		}

    @media screen and (max-width: 767px){
      .col-info{
        margin-top: 4px;
        margin-bottom: 4px;
        text-align: center;
      }
    }
	}
}

.contact-page-{
  padding-top: 0;

  .bg-image{
    @media screen and (min-width: 1800px){
      background-position: 100% 39% !important;
    }
  }

  .content-section{
    >.row{
      min-height: 70vh;
    }

    .col-title{
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: center;

      h1{
        color: #5d5d5d;
        font-size: 1.90rem;
        font-weight: 500;
      }
    }

    .col-form{
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: center;
    }
  }

  .map-section{
    margin-top: 15px;

    iframe{
      display: block;
      width: 100%;
      height: 350px;
      border: 0;
      border-top: 2px solid rgba(0,0,0,0.2);
    }
  }

}
