#services-page{
  position: relative;

  .img-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 48.5%;
    height: 100%;

    &:after{
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      width: 2%;
      height: 100%;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 76%);
    }

    @media screen and (max-width: 991px){
      width: 42%;
    }
    @media screen and (max-width: 767px){
      position: relative;
      width: 100%;
      height: 320px;
      background-position: center top 27% !important;

      &:after{
        display: none;
      }
    }
  }

  .main-section{
    padding-top: 35px;
    padding-bottom: 35px;

    .col-image{
      img{
        width: 100%;
      }
    }

    .col-info{
      color: #575757;
      line-height: 1.35;

      .col-s-title{
        padding-top: 10px;

        .title{
          text-align: left;
        }
      }

      .subtitle{
        font-size: 1.1rem;
      }

      ul{
        padding-left: 19px;
        margin-bottom: 0;
      }

      strong, b{
        font-weight: 600;
      }

      .box-services{
        margin-top: 16px;

        .col-service{
          padding-top: 14px;
          padding-bottom: 14px;
          text-align: center;

          .circle{
            margin-bottom: 15px;

            img{
              display: inline-block;
              width: 300px;
              max-width: 100%;
              border-radius: 50%;
              overflow: hidden;
              border: 2px solid #c8c8c7;
              box-shadow: 0 0 3px rgba(136, 133, 127, 0.9);
              background-color: $soft-gray;
            }
          }

          .title{
            color: #403f3d;
            font-size: 1.20rem;
            font-weight: 700;
            line-height: 1.05;
            text-transform: uppercase;
          }
        }
      }
    }

    @media screen and (max-width: 991px){
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @media screen and (max-width: 575px){
      .col-info{
        font-size: 0.96rem;
        line-height: 1.3;

        .col-s-title{
          padding-bottom: 5px;

          .title{
            text-align: center;
            font-size: 1.8rem;
          }
        }

        .subtitle{
          font-size: 1.0rem;
        }

        .box-services{
          .col-service{
            margin-bottom: 5px;
          }
        }
      }
    }
  }

}
