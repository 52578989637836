#courses-page{
  position: relative;

  .img-bg{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 767px){
      position: relative;
      width: 100%;
      height: 320px;
      background-position: center top 27% !important;

      &:after{
        display: none;
      }
    }
  }

  .main-section{
    padding-top: 35px;
    padding-bottom: 35px;

    >.row{
      @media screen and (min-width: 768px){
        min-height: 58vh;
      }
    }

    .col-image{
      img{
        width: 100%;
      }
    }

    .col-info{
      color: #575757;
      line-height: 1.35;

      .col-s-title{
        padding-top: 10px;

        .title{
          text-align: left;
        }
      }

      ul{
        padding-left: 19px;
        margin-bottom: 0;
      }

      strong, b{
        font-weight: 600;
      }
    }

    @media screen and (max-width: 991px){
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @media screen and (max-width: 575px){
      .col-info{
        font-size: 0.96rem;
        line-height: 1.3;

        .col-s-title{
          padding-bottom: 5px;

          .title{
            text-align: center;
            font-size: 1.8rem;
          }
        }
      }
    }
  }

}
